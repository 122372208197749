import React, {useState} from 'react';
import {Checkbox, Icon} from "framework7-react";
import {retrieveNotification} from "../api/partner";

const MyCheckbox = ({id, isChecked}) => {
    const [isFilled, setIsFilled] = useState(!isChecked);

    const handleChange = async (e) => {
        setIsFilled(!isFilled)
        console.log(isFilled)
        const res = await retrieveNotification(id, {is_new: isFilled})
        console.log(res)
    }
    return (
        <Checkbox onChange={handleChange} defaultChecked={!isChecked} value={isFilled}/>
    );
};

export default MyCheckbox;