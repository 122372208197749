import {configureStore} from '@reduxjs/toolkit';

export const ADD_CAR = 'ADD_CAR';
export const SET_CARS = 'SET_CARC';
export const REMOVE_CAR = 'REMOVE_CAR';
export const SET_USER = 'SET_USER';
export const SET_LEGAL_ENTITY = 'SET_LEGAL_ENTITY';


export const addCar = (car, id = null) => ({
    type: ADD_CAR,
    payload: { ...car, id }
});
export const setCars = (cars) => ({
    type: SET_CARS,
    payload: cars,
});

export const removeCar = (id) => ({
    type: REMOVE_CAR,
    payload: id
});

export const setUser = (user) => ({
    type: SET_USER,
    payload: user
});

export const setLegalEntity = (data) => ({
    type: SET_LEGAL_ENTITY,
    payload: data,
});


const initialState = {
    cars: [],
    user: null,
    legalEntity: null,
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_CAR:
            const existingCarIndex = state.cars.findIndex(car => car.id === action.payload.id);
            if (existingCarIndex !== -1) {
                const updatedCars = state.cars.map((car, index) =>
                    index === existingCarIndex ? { ...car, ...action.payload } : car
                );
                return {
                    ...state,
                    cars: updatedCars
                };
            } else {
                return {
                    ...state,
                    cars: [...state.cars, action.payload]
                };
            }
        case SET_CARS:
            return {
                ...state,
                cars: action.payload,
            }
        case REMOVE_CAR:
            return {
                ...state,
                cars: state.cars.filter(car => car.id !== action.payload)
            }
        case SET_USER:
            return {
                ...state,
                user: action.payload,
            }
        case SET_LEGAL_ENTITY:
            return {
                ...state,
                legalEntity: action.payload,
            }


        default:
            return state;
    }
}


const store = configureStore({
    reducer: rootReducer,
});

export default store;