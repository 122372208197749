import React, {useEffect, useState} from 'react';
import {getPartner, Login, updatePartner} from "../api/partner";
import axios from "axios";
import {App, f7, f7ready, Page, Preloader} from "framework7-react";
import {BeatLoader} from "react-spinners";
import {getHandbook} from "../api/car";
import {useTranslation} from "react-i18next";

const Splash = () => {

    const {i18n} = useTranslation();
    const [uniqueID, setUniqueID] = useState(Date.now().toString(36))
    const [token, setToken] = useState(null)
    const [refresh, setRefresh] = useState(null)

    const [brandOptions, setBrandOptions] = useState([]);
    // const [modelOptions, setModelOptions] = useState([]);
    const [transmissionOptions, setTransmissionOptions] = useState([]);
    const [equipmentOptions, setEquipmentOptions] = useState([]);
    const [colorOptions, setColorOptions] = useState([]);


    useEffect(() => {
        window.Telegram.WebApp.MainButton.color ="#4E80EE"
        console.log(window.Telegram.WebApp.initData);
    }, [])

    useEffect(() => {
        window.Telegram.WebApp.expand()

        const fetchHandbooks = async () => {
            const brandRes = await getHandbook('brand');
            localStorage.setItem('brandOptions', JSON.stringify(brandRes));

            const equipmentRes = await getHandbook('equipment');
            localStorage.setItem('equipmentOptions', JSON.stringify(equipmentRes));

            const colorRes = await getHandbook('color');
            localStorage.setItem('colorOptions', JSON.stringify(colorRes));

            const transmissionRes = await getHandbook('transmissiontype');
            localStorage.setItem('transmissionOptions', JSON.stringify(transmissionRes));

            const deleteReason = await getHandbook('deletereason');
            localStorage.setItem('deleteReason', JSON.stringify(deleteReason));


            const optionsRes = await getHandbook('options');
            localStorage.setItem('addonOptions', JSON.stringify(optionsRes));

            const salesRes = await getHandbook('saletype');
            localStorage.setItem('saletype', JSON.stringify(salesRes));

            const fuelRes = await getHandbook('fueltype');
            localStorage.setItem('fueltype', JSON.stringify(fuelRes));

            const opticsRes = await getHandbook('optics');
            localStorage.setItem('optics', JSON.stringify(opticsRes));

            const bodyTypeRes = await getHandbook('bodytype');
            console.log(bodyTypeRes);
            localStorage.setItem('bodyType', JSON.stringify(bodyTypeRes));

            const driveTypeRes = await getHandbook('drivetype');
            console.log(driveTypeRes);
            localStorage.setItem('driveType', JSON.stringify(driveTypeRes));

            const cabinRes = await getHandbook('cabin');
            console.log(cabinRes);
            localStorage.setItem('cabin', JSON.stringify(cabinRes));

            const mediaRes = await getHandbook('media');
            console.log(mediaRes);
            localStorage.setItem('media', JSON.stringify(mediaRes));

            const outsideRes = await getHandbook('outside');
            console.log(outsideRes);
            localStorage.setItem('outside', JSON.stringify(outsideRes));





        }

        setTimeout( () => {
            async function login() {
                try {
                    const res = await Login({init_data: window.Telegram.WebApp.initData})
                    console.log(res)
                    if (res.status === 'Username is required') {
                        window.Telegram.WebApp.close()
                    }
                    if (res.status === 'success') {
                        setToken(res.access)
                        setToken(res.refresh)
                        localStorage.setItem('token', res.access);
                        localStorage.setItem('refresh', res.refresh);
                        axios.defaults.headers.common['Authorization'] = `Bearer ${res.access}`
                        await fetchHandbooks()
                    }
                } catch (e) {
                    console.log(e)
                }
            }

            login().then(() => {
                getPartner().then(res => {
                    if (window.Telegram.WebApp.initDataUnsafe.user.username !== res.username) {
                        updatePartner({username: window.Telegram.WebApp.initDataUnsafe.user.username})
                    }
                    if (res.fully_registered === true) {
                        if (res.first_car_added) {
                            i18n.changeLanguage(res.language)
                            console.log('fully registered', uniqueID)
                            if (window.Telegram.WebApp.initDataUnsafe.start_param) {
                                f7.views.main.router.navigate(`/${window.Telegram.WebApp.initDataUnsafe.start_param}/`)
                                return;
                            }

                            f7.views.main.router.navigate('/main',
                                {
                                    props: {
                                        uniqueID: uniqueID
                                    }
                                }
                            );
                        } else {
                            f7.views.main.router.navigate('/simplecarform')
                        }
                    }
                    else {
                        console.log("here" + res)
                        f7.views.main.router.navigate('/');
                    }
                }).catch(err => {
                    console.log(err)
                })
            })

        }, 2000)



    }, []);



    return (
        <Page>
            <div style={{display: 'flex', height:'100%', alignItems: 'center', justifyContent: 'center'}}>
                <Preloader size={20} color="#4E80EE"/>
            </div>

        </Page>
    );
};

export default Splash;
