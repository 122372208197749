import React, {useEffect, useState} from 'react';
import {
    Badge,
    BlockTitle,
    Button, Checkbox,
    f7,
    Icon,
    List,
    ListItem,
    Page,
    SwipeoutActions,
    SwipeoutButton
} from "framework7-react";
import {getNotifications, updatePartner} from "../api/partner";
import {updateCar} from "../api/car";
import MyCheckbox from "../Components/Checkmark";

const Notifications = () => {
    const [notifications, setNotifications] = useState([])
    const [checked, setChecked] = useState(false)
    useEffect(() => {
        const fetchNotifications = async () => {
            const res = await getNotifications();
            setNotifications(res.data);
            console.log(res)
        }
        fetchNotifications()
    }, [])


    /*const handleCheckedState = async () => {
        setChecked(!checked)
        const res = await updateCar(notifications.id, {is_new: checked})
        console.log(res)
    }*/
    const handleBackBtn = () => {
        f7.views.main.router.navigate("/main")
    }

    const handlePtrRefresh = (done) => {
        window.Telegram.WebApp.HapticFeedback.impactOccurred("medium");

        setTimeout(() => {
            done();
        }, 2000)
    }
    useEffect(() => {
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.MainButton.isVisible = false;

        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn);

        return () => {
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn)
        }
    }, [])
    return (
        <Page ptr ptrMousewheel={true} onPtrRefresh={handlePtrRefresh}>
            <BlockTitle style={{fontSize: 25, lineHeight: 1.2}}>Уведомления</BlockTitle>
            <List mediaList strong inset dividersIos>
                {notifications.map((item, index) => (
                    <ListItem
                        title={item?.registered_name}
                    >
                        <p style={{margin: 0,marginTop: 20,marginBottom:20, padding: 0}}><span style={{fontWeight: 'bold', textWrap: 'wrap', maxWidth: 100}}>Объявление:</span> {item?.car?.model_id?.name}, {item?.car?.brand_id?.name} {(Number.parseInt(item?.car?.price_usd).toLocaleString('en-US').replace(/,/g, ' '))} $</p>
                        <div style={{display: 'flex', margin: 0, padding: 0}} slot='after'>
                            <div style={{display: 'flex', alignItems:'center'}}>
                                <p style={{margin: 0, padding: 0, paddingRight: 4}}>
                                    {item?.created_at}
                                </p>
                                <MyCheckbox id={item?.id} isChecked={item?.is_new} />
                            </div>
                        </div>
                        <p style={{margin: 0, padding: 0, marginTop: 8, marginLeft:2, marginRight: 2}} className="grid grid-cols-2 grid-gap">
                            <Button onClick={() => window.Telegram.WebApp.openTelegramLink(`https://t.me/${item?.username}`)}   round  style={{backgroundColor: '#4E80EE', fontWeight: 'bold', borderRadius: 16, textTransform: 'none', fontSize: 15}} fill>В чат</Button>
                            <Button onClick={() => window.open(`tel:${item?.phone}`)}    style={{backgroundColor: '#5ad58b', fontWeight: 'bold', borderRadius: 16, textTransform: 'none', fontSize: 15}} fill>
                                Позвонить
                            </Button>
                        </p>

                    </ListItem>
                ))
                }
            </List>
        </Page>
    );
};

export default Notifications;
