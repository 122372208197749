import Maps from "../Components/Maps";
import {f7, Page} from "framework7-react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";


export default function MapScreen () {
    const [address, setAddress] = useState({address:'', coordinates: {lat: '', lng: ''}});
    const {t, i18n} = useTranslation();

    const handleDataFromChild = (address) => {
        setAddress({address1: address[0], address2: address[1]});
    };
    function handleBackBtn() {
        f7.view.main.router.navigate('/legalentityform')
    }
    function handleMainBtn () {
        f7.views.main.router.navigate('/legalentityform', {props: {address}});
    }
    useEffect(() => {

        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.MainButton.text = t('pickLocationBtn')
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);
        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn);

        return () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        }


    }, [address, handleMainBtn])
    return (
        <Page onPageAfterOut={() => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        }}>
            <div>
                <Maps onData={handleDataFromChild}/>
            </div>
        </Page>
    )
}