import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next)
    .init({
        fallbackLng: "uz",
        lng: "uz",
        interpolation: {
            escapeValue: false,
        },
        resources: {
            ru: {
                translation: {
                    gotoMarket: 'Перейти в маркетплейс',
                    interfaceLang: 'Язык интерфейса',
                    welcome: 'Добро пожаловать',
                    welcomeDescription: ' - покупайте и продавайте авто быстро и надежно',
                    chooseLang: 'Выберите язык 🇷🇺',
                    continueBtn: 'ПРОДОЛЖИТЬ',
                    descriptionQuality: 'Качество',
                    descriptionSafe: 'Безопасная оплата',
                    descriptionCost: 'Низкая цена',
                    topDesc: 'Выводит машину в топ',
                    top: 'Топ',

                    register: "Регистрация",
                    registerName: 'Имя',
                    registerWriteName: 'Введите ваше имя',
                    registerWritePhone: 'Укажите свой рабочий номер телефона',
                    registerPickDate: 'Выбрать дату',
                    registerPickYourDate: 'Укажите ваш день рождения',
                    registerRegion: 'Регион',
                    registerWriteUsername: 'Ваш username в телеграмме',
                    goToCarFormBtn: 'Перейти к добавлению машины',
                    chooseBirthdayErr: 'Укажите свой день рождения',
                    chooseRegionErr: 'Укажите регион',
                    chooseUsernameErr: 'Укажите username',
                    chooseValidPhone: 'Укажите корректный номер телефона',


                    yetEmpty: 'Пока пусто',
                    carAddition: 'Добавление машины',
                    brand: 'Марка',
                    model: 'Модель',
                    position: 'Позиция',
                    provideCarData: 'Укажите комплектацию вашего автомобиля',
                    color: 'Цвет',
                    carColor: 'Цвет вашего автомобиля',
                    transmission: 'Коробка передач',
                    chooseYear: 'Указать год',
                    pickYear: 'Выберите год производста вашего автомобиля',
                    cost: 'Цена',
                    mileage: 'Пробег',
                    description: 'Описание',
                    enableTop: 'Пожалуйста, заполните все поля чтобы включить опцию Топ',
                    priceSum: 'Цена в сумах:',
                    writeDescription: 'Введите описание вашего автомобиля (50-200 слов)',
                    pickImage: 'Выберите фото',
                    img:'Изображение',
                    canTrade: 'Возможность торгов',
                    canTradeDesc: 'Позволяет покупателям договариваться о цене',
                    provideCarDataErr: "Укажите полную комплекатцию автомобиля",
                    pickColorErr: 'Укажите цвет',
                    pickTransmissionErr: 'Укажите коробку передач',
                    pickYearErr: 'Укажите год',
                    pickCostErr: 'Укажите корректную цену',
                    pickMileageErr: 'Укажите пробег',
                    pickDescriptionErr: 'Введите описание',
                    pickImageErr: 'Укажите фото',
                    ready: 'Готово',

                    myCars: 'Мои машины',
                    myData: 'Мои данные',


                    enterText: 'Введите текст',
                    personalData: 'Личные данные',
                    addCarBtn: 'Добавить машину',
                    deleteCar: 'Удалить машину',
                    saveChangesBtn: 'Сохранить изменения',
                    options:'Опции',
                    optics: 'Оптика',
                    saletype: 'Тип продажи',
                    fueltype: 'Тип топлива',
                    bodytype: 'Кузов',
                    drive: 'Привод',
                    outside:'Снаружи',
                    media: 'Медиа',
                    cabin: 'Салон',


                    becomeLegalEntity: 'Стать юридическим лицом',
                    legalEntityForm: 'Оформление юридического статуса',
                    dealerName: 'Название автосалона',
                    chooseDealerName: 'Введите название вашего автосалона',
                    location: 'Локация',
                    chooseLocation: 'Локация вашего автосалона',
                    chooseLogo: 'Выберите логотип',
                    sendRequestBtn: 'Подать заявку',
                    pickLocationBtn: 'Указать эту локацию',

                    carDeletion: 'Удаление машины',
                    carDelDesc: 'Пожалуйста укажите причину удаления',
                    deleteText: 'Удалить машину',
                    reason: 'Причина',
                    deleteDescription: 'Пожалуйста укажите причину деактивации',


                    regions: {
                        tashkent: "г. Ташкент",
                        tashkent_obl: "Ташкентская область",
                        fergana: "Ферганская область",
                        samarkand: "Самаркандская область",
                        bukhara: "Бухарская область",
                        andijan: "Андижанская область",
                        namangan: "Наманганская область",
                        navoi: "Навоийская область",
                        khorezm: "Хорезмская область",
                        surkhandarya: "Сурхандарьинская область",
                        kashkadarya: "Кашкадарьинская область",
                        syrdarya: "Сырдарьинская область",
                        jizzakh: "Джизакская область",
                        karakalpakstan: "Республика Каракалпакстан",
                    },












                },
            },
            uz: {
                translation: {
                    topDesc: 'Mashinangizni topka chikaradi',
                    top: 'Top',
                    gotoMarket: "Marketplacega o'tish",
                    priceSum: 'Narx somda:',
                    interfaceLang: 'Interface tili',
                    welcome: 'Xush kelibsiz',
                    welcomeDescription: ' - mashinalarni tez va ishonchli sotib oling va soting',
                    chooseLang: 'Tilni tanlang 🇺🇿',
                    continueBtn: 'DAVOM ETISH',
                    descriptionQuality: 'Sifat',
                    descriptionSafe: `Xavfsiz to'lov`,
                    descriptionCost: 'Arzon narx',
                    enterText: 'Tekstni kiriting',

                    register: "Roʻyxatdan oʻtish",
                    registerName: 'Ism',
                    registerWriteName: 'Ismingizni kiriting',
                    registerWritePhone: 'Telefon raqamingizni kiriting',
                    registerPickDate: 'Sana tanlang',
                    registerPickYourDate: `Iltimos, tug'ilgan kuningizni kiriting`,
                    registerRegion: 'Hududingiz',
                    registerWriteUsername: 'Telegramdagi foydalanuvchi ismingiz(@username)',
                    goToCarFormBtn: `Mashinani qo'shishga o'ting`,
                    chooseBirthdayErr: `Tug'ilgan kuningizni kiriting`,
                    chooseRegionErr: 'Hududingizni belgilang',
                    chooseUsernameErr: 'Telegramdagi foydalanuvchi ismingiz(@username) belgilang',
                    chooseValidPhone: `Iltimos, to'g'ri telefon raqamingiznii kiriting`,

                    deleteText: 'Mashinani ochirish',
                    deleteDescription: `Iltimos, o'chirish sababini yozing`,
                    reason: 'Sabab',

                    bodytype: 'Кuzov',
                    drive: 'Privod',

                    yetEmpty: 'Hozircha bosh',
                    ready: 'Tayyor',
                    carAddition: `Mashinani qo'shish`,
                    brand: 'Marka',
                    model: 'Model',
                    position: 'Pozitsiya',
                    provideCarData: 'Mashinangizni komplektatsiyasini belgilang',
                    color: 'Rang',
                    carColor: 'Mashinangizni rangi',
                    transmission: 'Uzatish qutisi',
                    chooseYear: 'Yilini kiriting',
                    img:'Surat',
                    pickYear: 'Mashinangizni ishlab chiqarilgan yilini tanlang',
                    cost: 'Narxi',
                    mileage: 'Probeg',
                    description: `Qo'shimcha ma'lumot`,
                    writeDescription: `Mashinangizni qo'shimcha ma'lumotini kiriting (50-200 so'z)`,
                    pickImage: `Mashinangizni rasmini qo'shing`,
                    canTrade: 'Kelishish imkoniyati',
                    canTradeDesc: 'Xaridorlarga narxni kelishib olish imkonini beradi',
                    provideCarDataErr: "Iltimos, mashinaning to'liq komplektatsiyasini ko'rsating",
                    pickColorErr: 'Rangni belgilang',
                    pickTransmissionErr: 'Uzatish qutisini belgilang',
                    pickYearErr: 'Yilni kiriting',
                    pickCostErr: 'Toʻgʻri narxni kiriting',
                    pickMileageErr: 'Probegni kiriting',
                    pickDescriptionErr: `Qo'shimcha ma'lumotni kiriting`,
                    pickImageErr: `Mashinangizni rasmini qo'shing`,

                    myCars: 'Mening mashinalarim',
                    myData: `Mening ma'lumotlarim`,

                    enableTop: 'Iltimos, hama inputlani toldiring Top opsiasiga buyrtma berish uchun',

                    personalData: `Shaxsiy ma'lumotlarim`,
                    addCarBtn: `Mashinangizni qo'shing`,
                    deleteCar: `Mashinani o'chirish`,
                    saveChangesBtn: `O'zgarishlarni saqlash`,
                    options:'Opsiyalar',
                    optics: 'Optika',
                    saletype: 'Sotuv yoli',
                    fueltype: 'Yoqilgi turi',

                    outside:'Tashiqari',
                    media: 'Media',
                    cabin: 'Salon',

                    becomeLegalEntity: 'Yuridik shaxsga aylanish',
                    legalEntityForm: `Huquqiy yuridik maqomni ro'yxatdan o'tkazish`,
                    dealerName: 'avtosalonning nomi',
                    chooseDealerName: 'Avtosalonningizni nomini kiriting',
                    location: 'Joylashuv',
                    chooseLocation: 'Avtosalonningizni joylashuvi',
                    chooseLogo: 'Logotipingizni kiriting',
                    sendRequestBtn: 'Arizani yuboring',
                    pickLocationBtn: 'Bu joylashuvni belgilang',

                    regions: {
                        tashkent: "Toshkent shahri",
                        tashkent_obl: "Toshkent viloyati",
                        fergana: "Farg‘ona viloyati",
                        samarkand: "Samarqand viloyati",
                        bukhara: "Buxoro viloyati",
                        andijan: "Andijon viloyati",
                        namangan: "Namangan viloyati",
                        navoi: "Navoiy viloyati",
                        khorezm: "Xorazm viloyati",
                        surkhandarya: "Surxondaryo viloyati",
                        kashkadarya: "Qashqadaryo viloyati",
                        syrdarya: "Sirdaryo viloyati",
                        jizzakh: "Jizzax viloyati",
                        karakalpakstan: "Qoraqalpog‘iston Respublikasi",
                    },
                },
            },
        },
    });

export default i18next;