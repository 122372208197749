import React, {useEffect, useReducer, useState} from 'react';
import {BlockFooter, BlockHeader, BlockTitle, f7, List, ListInput, ListItem, Page} from "framework7-react";
import {deleteCar} from "../api/car";
import {useTranslation} from "react-i18next";

const DeleteCar = (props) => {
    const car = props.car
    const {t, i18n} = useTranslation();
    const [reasonOptions, setReasonOptions] = useState(JSON.parse(localStorage.getItem('deleteReason')))
    const [reason, setReason] = useState('')
    const [isRequired, setIsRequired] = useState(false)
    const [customReason, setCustomReason] = useState('');
    const [price, setPrice] = useState('')
    const initialState = {
        errors: {},
    };



    function formReducer(state, action) {
        switch (action.type) {
            case 'field':
                return {
                    ...state,
                    [action.fieldName]: action.payload,
                };
            case 'setErrors':
                return {
                    ...state,
                    errors: action.payload,
                };
            default:
                return state;
        }
    }

    const [state, localDispatch] = useReducer(formReducer, initialState);

    const validateForm = () => {
        const errors = {};
        if (!reason) errors.reason = 'Укажите причину';

        localDispatch({ type: 'setErrors', payload: errors });
        return Object.keys(errors).length === 0;
    };

    const handleMainBtn = async () => {
        if (validateForm()) {
            window.Telegram.WebApp.MainButton.showProgress((leave = false) => {
            })
            let reasonData = {
                delete_reason_id: reason,
            }

            if (isRequired) {
                reasonData.delete_reason_description = customReason
            }

            console.log(reasonData)
            const res = await deleteCar(car.id, reasonData)
            console.log(res)
            window.Telegram.WebApp.MainButton.hideProgress();
            f7.views.main.router.navigate('/mycars')
        }
    }
    const handleBackBtn = async () => {
        f7.views.main.router.navigate('/caredit', {props: {car}})
    }

    useEffect(() => {
        console.log(reason)
    }, [reason])

    useEffect(() => {
        console.log(reasonOptions)
        window.Telegram.WebApp.MainButton.text = t('deleteText')
        window.Telegram.WebApp.MainButton.color = "#FF3B30"



        return (() => {
            window.Telegram.WebApp.MainButton.color ="#4E80EE"
        })
    }, [])

    useEffect(() => {
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);
        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn);

        return( () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        });
    }, [handleMainBtn])
    return (
        <Page onPageAfterOut={() => {
            window.Telegram.WebApp.MainButton.color ="#4E80EE"
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
            }
        }>
            <BlockTitle style={{fontSize: 25, lineHeight: 1.2}}>{t('deleteText')}</BlockTitle>
            <BlockHeader>{t('deleteDescription')}</BlockHeader>
            <List strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                }}
                          errorMessageForce={state.errors.region}
                          errorMessage={state.errors.region}
                          title={t('reason')}
                          smartSelect
                >
                    <select value={reason} name="reason" onChange={(e) => {setReason(e.target.value); setIsRequired(reasonOptions.find(option => option.id === parseInt(e.target.value)).is_required)}}
                    >
                        <option value='' defaultChecked disabled></option>
                        {reasonOptions.map((item) => (
                                <option key={item.id} value={item.id}>{i18n.language === 'ru' ? item.name_ru : item.name_uz}</option>
                            )
                        )}
                    </select>
                </ListItem>
            </List>


            {reason === '1' &&
                (
                <List strongIos dividersIos insetIos>
                <ListItem className={state.errors.price && 'item-input-invalid'}>
                    <div className="item-input-wrap">
                        <input
                            type="text"
                            inputMode="numeric"
                            placeholder={i18n.language === 'ru' ? reasonOptions.find(option => option.id === parseInt(reason))?.additional_question_ru : reasonOptions.find(option => option.id === parseInt(reason))?.additional_question_uz}
                            value={customReason}
                            onChange={(e) => {if (!Number.isNaN(+e.target.value)) setCustomReason(e.target.value)}}
                        />
                        {state.errors.price && <div className="input-error-message">{state.errors.price}</div>}
                    </div>
                    <div className="item-after">$</div>
                </ListItem>
            </List>)}



            {isRequired && (
                <List strongIos dividersIos insetIos>
                <ListInput
                    errorMessage={state.errors.description}
                    errorMessageForce={state.errors.description}
                    type="textarea"
                    placeholder={i18n.language === 'ru' ? reasonOptions.find(option => option.id === parseInt(reason))?.additional_question_ru : reasonOptions.find(option => option.id === parseInt(reason))?.additional_question_uz}
                    value={customReason}
                    onChange={(e) => setCustomReason(e.target.value)}
                    maxlength={200}
                    minlength={50}
                />
            </List>)}

            {state.errors.reason && !reason && <BlockFooter style={{marginTop: -25, color:"#FF3B30"}}>{state.errors.reason}</BlockFooter>}
        </Page>
    );
};

export default DeleteCar;
